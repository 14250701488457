var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
		'c-block-list-contact-group-custom mx-auto',

		{
			'c-block-list-contact-group-custom--wide >=1024:w-full':
				_vm.activeElements >= 3,
			'>=1024:w-8col': _vm.activeElements === 2,
			'>=1024:w-6col': _vm.activeElements === 1,
		} ],attrs:{"id":("contact-group-" + _vm._uid)}},[(_vm.title)?_c('LongReadTarget',{attrs:{"container-id":("contact-group-" + _vm._uid),"title":_vm.title}},[_c('BaseH2',{staticClass:"text-primary-button mb-sm <1024:hidden",domProps:{"textContent":_vm._s(_vm.title)}})],1):_vm._e(),_vm._v(" "),(_vm.contactgroup)?_c('div',{class:[
			'grid gap-x-sm gap-y-sm <1024:hidden grid-cols',

			{
				'>=1024:grid-cols-3': _vm.activeElements >= 3,
				'>=1024:grid-cols-2': _vm.activeElements === 2,
				'>=1024:grid-cols-1': _vm.activeElements === 1,
			} ]},[(_vm.showPhoneSection)?_c('div',{staticClass:"\n\t\t\t\tbg-primary-button\n\t\t\t\ttext-white\n\t\t\t\trounded-lg\n\t\t\t\tp-md\n\t\t\t\tflex flex-col\n\t\t\t\trelative\n\t\t\t"},[_c('BaseH3',{staticClass:"mt-8"},[_vm._v(_vm._s(_vm.titles.phoneTitle))]),_vm._v(" "),_c('span',{staticClass:"mt-sm whitespace-pre-wrap"},[_vm._v(_vm._s(_vm.contactgroup.phoneText))]),_vm._v(" "),(_vm.contactgroup.phone)?_c('a',{staticClass:"mt-md flex items-center w-fit hover:underline",attrs:{"href":("tel:" + (_vm.contactgroup.phone))}},[_c('SvgPhoneIcon',{staticClass:"w-14 h-14 mr-12"}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.contactgroup.phone))])],1):_vm._e(),_vm._v(" "),(
					!_vm.contactgroup.phoneOpenHours.every(
						function (item) { return item.isOpen === false; }
					)
				)?_c('div',{staticClass:"mt-sm flex items-baseline w-fit mb-xl"},[_c('SvgClockIcon',{staticClass:"w-14 h-14 mr-12"}),_vm._v(" "),_c('div',{staticClass:"flex flex-col"},_vm._l((_vm.contactgroup.phoneOpenHours),function(hours,index){return _c('div',{key:index,class:hours.isOpen ? '' : 'hidden'},[(hours.isOpen)?_c('div',{staticClass:"flex justify-between"},[_c('span',[_vm._v(_vm._s(hours.dayOfTheWeek))]),_vm._v(" "),_c('div',{staticClass:"ml-xs",domProps:{"innerHTML":_vm._s(
									_vm.calculateHours(hours.hoursOfBusiness)
								)}})]):_vm._e()])}),0)],1):_vm._e(),_vm._v(" "),(_vm.contactgroup.phoneLink)?_c('NuxtLinkExt',{staticClass:"block w-fit absolute bottom-0",attrs:{"to":_vm.contactgroup.phoneLink.url,"target":_vm.contactgroup.phoneLink.target}},[_c('BaseButton',{staticClass:"w-fit mb-md",attrs:{"tag":"div","outline":"white-1"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.contactgroup.phoneLink.name ===
							'Klik og se mere'
								? _vm.titles.phoneButtonTitle
								: _vm.contactgroup.phoneLink.name)+"\n\t\t\t\t\t")]},proxy:true}],null,false,3976946235)})],1):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.showMeetupSection)?_c('div',{staticClass:"\n\t\t\t\tbg-primary-button\n\t\t\t\ttext-white\n\t\t\t\trounded-lg\n\t\t\t\tp-md\n\t\t\t\tflex flex-col\n\t\t\t\trelative\n\t\t\t"},[_c('BaseH3',{staticClass:"mt-8"},[_vm._v(_vm._s(_vm.titles.meetTitle))]),_vm._v(" "),_c('span',{staticClass:"mt-sm whitespace-pre-wrap"},[_vm._v(_vm._s(_vm.contactgroup.meetText))]),_vm._v(" "),(_vm.contactgroup.meetAddress.length > 0)?_c('div',{staticClass:"mt-md flex items-start w-fit underline"},[_c('SvgLocationIcon',{staticClass:"w-12 h-14 mr-12"}),_vm._v(" "),_c('div',{staticClass:"flex flex-col"},_vm._l((_vm.contactgroup.meetAddress),function(address,index){return _c('span',{key:index},[_vm._v(_vm._s(address))])}),0)],1):_vm._e(),_vm._v(" "),(
					!_vm.contactgroup.meetOpenHours.every(
						function (item) { return item.isOpen === false; }
					)
				)?_c('div',{staticClass:"mt-sm flex items-baseline w-fit mb-xl"},[_c('SvgClockIcon',{staticClass:"w-14 h-14 mr-12"}),_vm._v(" "),_c('div',{staticClass:"flex flex-col"},_vm._l((_vm.contactgroup.meetOpenHours),function(hours,index){return _c('div',{key:index,class:hours.isOpen ? '' : 'hidden'},[(hours.isOpen)?_c('div',{staticClass:"flex justify-between"},[_c('span',[_vm._v(_vm._s(hours.dayOfTheWeek))]),_vm._v(" "),_c('div',{staticClass:"ml-xs",domProps:{"innerHTML":_vm._s(
									_vm.calculateHours(hours.hoursOfBusiness)
								)}})]):_vm._e()])}),0)],1):_vm._e(),_vm._v(" "),(_vm.contactgroup.meetLink)?_c('NuxtLinkExt',{staticClass:"block w-fit absolute bottom-0",attrs:{"to":_vm.contactgroup.meetLink.url,"target":_vm.contactgroup.meetLink.target}},[_c('BaseButton',{staticClass:"w-fit mb-md",attrs:{"tag":"div","outline":"white-1"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.contactgroup.meetLink.name === 'Klik og se mere'
								? _vm.titles.meetButtonTitle
								: _vm.contactgroup.meetLink.name)+"\n\t\t\t\t\t")]},proxy:true}],null,false,335531677)})],1):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.showMailSection)?_c('div',{staticClass:"flex flex-col"},[(
					_vm.contactgroup.mailSafemail &&
					_vm.contactgroup.mailSafemail[0]
				)?_c('div',{staticClass:"\n\t\t\t\t\tbg-secondary\n\t\t\t\t\ttext-black\n\t\t\t\t\trounded-lg\n\t\t\t\t\tp-md\n\t\t\t\t\tflex flex-col\n\t\t\t\t\trelative\n\t\t\t\t\tflex-1\n\t\t\t\t",class:_vm.contactgroup.mailRegularForm ? 'mb-sm' : 'h-full'},[_c('BaseH3',{staticClass:"mt-8"},[_vm._v(_vm._s(_vm.titles.mailSafeemailTitle))]),_vm._v(" "),_c('span',{staticClass:"mt-sm mb-lg"},[_vm._v(_vm._s(_vm.titles.mailSafemailText))]),_vm._v(" "),_c('NuxtLinkExt',{staticClass:"block w-fit bottom-0",attrs:{"to":_vm.contactgroup.mailSafemail[0].url,"target":'_blank'}},[_c('BaseButton',{staticClass:"w-fit",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_vm._v(_vm._s(_vm.titles.mailSafeemailButtonTitle))]},proxy:true},{key:"icon",fn:function(){return [_c('div',{staticClass:"flex"},[_c('SvgExternalArrowIcon',{staticClass:"w-14 h-14 mr-12"}),_vm._v(" "),_c('SvgMitidIcon',{staticClass:"w-32 h-14"})],1)]},proxy:true}],null,false,1819661079)})],1)],1):_vm._e(),_vm._v(" "),(
					_vm.contactgroup.mailRegularForm &&
					_vm.contactgroup.mailRegularForm[0]
				)?_c('div',{staticClass:"\n\t\t\t\t\tbg-secondary\n\t\t\t\t\ttext-black\n\t\t\t\t\trounded-lg\n\t\t\t\t\tp-md\n\t\t\t\t\tflex flex-col flex-1\n\t\t\t\t\trelative\n\t\t\t\t",class:_vm.contactgroup.mailSafemail ? '' : 'h-full'},[_c('BaseH3',{staticClass:"mt-8"},[_vm._v(_vm._s(_vm.titles.mailRegularTitle))]),_vm._v(" "),_c('span',{staticClass:"mt-sm mb-lg"},[_vm._v(_vm._s(_vm.titles.mailRegularText))]),_vm._v(" "),_c('NuxtLinkExt',{staticClass:"block w-fit bottom-0",attrs:{"to":_vm.mailLink,"target":'_self'}},[_c('BaseButton',{staticClass:"w-fit",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_vm._v(_vm._s(_vm.titles.mailRegularButtonTitle))]},proxy:true}],null,false,1354831939)})],1)],1):_vm._e()]):_vm._e()]):_vm._e(),_vm._v(" "),_c('div',{staticClass:">=1024:hidden"},[_c('AccordionLayout',_vm._b({scopedSlots:_vm._u([{key:"header",fn:function(ref){
				var item = ref.item;
return [_c('BaseH3',{attrs:{"tag":"h3"},domProps:{"textContent":_vm._s(item.title)}})]}},{key:"panel",fn:function(ref){
				var item = ref.item;
return [_c('div',{staticClass:"relative h-full pb-xl"},[_c('span',[_vm._v(_vm._s(item.teaser))]),_vm._v(" "),(item.phone)?_c('a',{staticClass:"\n\t\t\t\t\t\t\tmt-md\n\t\t\t\t\t\t\tflex\n\t\t\t\t\t\t\titems-center\n\t\t\t\t\t\t\tw-fit\n\t\t\t\t\t\t\thover:underline\n\t\t\t\t\t\t",attrs:{"href":("tel:" + (item.phone))}},[_c('SvgPhoneIcon',{staticClass:"w-14 h-14 mr-12"}),_vm._v(" "),_c('span',[_vm._v(_vm._s(item.phone))])],1):_vm._e(),_vm._v(" "),(item.location && item.location.length > 0)?_c('div',{staticClass:"mt-md flex items-center w-fit underline"},[_c('SvgLocationIcon',{staticClass:"w-12 h-14 mr-12"}),_vm._v(" "),_c('span',[_vm._v(_vm._s(item.location.join(' ')))])],1):_vm._e(),_vm._v(" "),(
							item.hours &&
							!item.hours.every(
								function (thing) { return thing.isOpen === false; }
							)
						)?_c('div',{staticClass:"mt-sm flex items-baseline w-fit"},[_c('SvgClockIcon',{staticClass:"w-14 h-14 mr-12"}),_vm._v(" "),_c('div',{staticClass:"flex flex-col"},_vm._l((item.hours),function(hours,index){return _c('div',{key:index,class:hours.isOpen ? '' : 'hidden'},[(hours.isOpen)?_c('div',{staticClass:"flex justify-between"},[_c('span',[_vm._v(_vm._s(hours.dayOfTheWeek))]),_vm._v(" "),_c('div',{staticClass:"ml-xs",domProps:{"innerHTML":_vm._s(
											_vm.calculateHours(
												hours.hoursOfBusiness
											)
										)}})]):_vm._e()])}),0)],1):_vm._e(),_vm._v(" "),(item && item.url)?_c('NuxtLinkExt',{staticClass:"block w-fit absolute bottom-0",attrs:{"to":item.url,"target":'_self'}},[_c('BaseButton',{staticClass:"w-fit",attrs:{"tag":"div","outline":item.phone || item.location
									? 'white-1'
									: 'none'},scopedSlots:_vm._u([{key:"default",fn:function(){return [_vm._v(_vm._s(item.button))]},proxy:true},(item.myid || item.external)?{key:"icon",fn:function(){return [_c('div',{staticClass:"flex"},[(item.myid || item.external)?_c('SvgExternalArrowIcon',{staticClass:"w-12 h-12"}):_vm._e(),_vm._v(" "),(item.myid)?_c('SvgMitidIcon',{staticClass:"w-32 h-14 ml-12"}):_vm._e()],1)]},proxy:true}:null],null,true)})],1):_vm._e()],1)]}}])},'AccordionLayout',{ title: _vm.title, elements: _vm.elements, groupkey: _vm.groupkey },false))],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }